/**
 * Gibraltar API for PWD
 * API for presenting Gibraltar patient data.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: azheng@insulet.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { InsightTimeOfDayInsightsPropInner } from './insightTimeOfDayInsightsPropInner';
import { TimeOfDayType } from './timeOfDayType';

export interface InsightTimeOfDayInsightsProp extends TimeOfDayType { 
    insights?: Array<InsightTimeOfDayInsightsPropInner>;
    daysWithEvents?: Array<number>;
}
export namespace InsightTimeOfDayInsightsProp {
}