import React from 'react';
import { useOktaAuth } from '@okta/okta-react';

import Menu from './menu';

import MenuHelper from '../../helpers/MenuHelper';
import { ICallback, IHome, IMenuEntry, ITranslator } from '../../types';
import 'bootstrap/dist/css/bootstrap.css'; // Import precompiled Bootstrap css
import '../../app/App.scss';

function Header({
    home,
    menuOpenCloseCb,
    translate,
}: {
    home: IHome;
    menuOpenCloseCb: ICallback<void>;
    translate: ITranslator;
}) {
    const authState = useOktaAuth()?.authState;
    const menuEntries = MenuHelper.MenuEntries.filter(
        (e) =>
            (authState?.isAuthenticated && !e.hidden && !e.disabled) ||
            (!authState?.isAuthenticated && e.key === MenuHelper.MenuEntrySignIn.key)
    );

    return (
        <Menu
            home={home}
            menuEntries={menuEntries}
            isMenuOpen={home.isMenuOpen}
            menuOpenCloseCb={({ isMenuOpen, menuEntry }: { isMenuOpen: boolean; menuEntry: IMenuEntry }) =>
                menuOpenCloseCb({ isMenuOpen, menuEntry })
            }
            translate={translate}
        />
    );
}

export default Header;
