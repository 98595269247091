import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';

import { ICallback } from '../../types';
import styleGeneral from '../../styles/general.module.scss';

function CheckSingle({
    id,
    cbClickFn,
    captionCb,
    checked,
}: {
    id: string;
    cbClickFn: ICallback<void>;
    captionCb: ICallback<React.ReactNode>;
    checked: boolean;
}) {
    return (
        captionCb && (
            <FormControlLabel
                control={
                    <Checkbox
                        id={id}
                        data-testid={id}
                        checked={checked}
                        onChange={(e) => cbClickFn(e?.target)}
                        className={styleGeneral.checkbox}
                    />
                }
                label={captionCb()}
            />
        )
    );
}

export default CheckSingle;
