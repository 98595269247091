/**
 * Gibraltar API for PWD
 * API for presenting Gibraltar patient data.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: azheng@insulet.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Patient glucose device bolus delivery information
 */
export interface Bolus { 
    /**
     * Type of the bolus
     */
    bolusType: Bolus.BolusTypeEnum;
    /**
     * ISO 8601 timestamp of receiving bolus by device
     */
    bolusDateTime: string;
    /**
     * Carbs provided by user
     */
    carbs: number;
    /**
     * Suggested correction bolus
     */
    correctionBolus: number;
    /**
     * Suggested meal bolus
     */
    mealBolus: number;
    /**
     * Delivered bolus
     */
    totalBolus: number;
}
export namespace Bolus {
    export type BolusTypeEnum = 'EXTENDED' | 'IMMIDIATE';
    export const BolusTypeEnum = {
        EXTENDED: 'EXTENDED' as BolusTypeEnum,
        IMMIDIATE: 'IMMIDIATE' as BolusTypeEnum
    };
}