import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { sendAzureEvent } from 'src/helpers/appInsights';

import InsightEdNotes from '../components/insight/InsightEdNotes';
import InsightUserFeedback from '../components/insight/InsightUserFeedback';
import InsightEdWarning from '../components/insight/InsightEdWarning';
import InsightHelper from '../helpers/InsightHelper';
import SystemHelper from '../helpers/SystemHelper';
import {
    anchorEventActionCreator,
    contributingEventMetaDataActionCreator,
    dashboardMetaDataActionCreator,
    insightIdActionCreator,
    intersessionDataSaveActionCreator,
    sendFeedback,
} from '../store/home/home.slice';
import InsightDetail from '../components/insight/InsightDetail';
import styleGeneral from '../styles/general.module.scss';
import { IInsightFeedback, IState } from '../types';
import BtnBack from '../components/common/btnBack';
import ConstantsHelper from '../helpers/ConstantsHelper';
import NavigationHelper from '../helpers/NavigationHelper';
import UiHelper from '../helpers/UiHelper';
import ContentWrapperAll from '../components/common/contentWrappers/ContentWrapperAll';
import { homeInitialState } from '../store/home';

function InsightDetails(props: any) {
    const dispatch = useDispatch();
    const bodyRef = useRef<HTMLDivElement>();
    const home = SystemHelper.GetCleanState(
        useSelector((state: IState) => state.home),
        homeInitialState
    );
    const translate = (key: string, subs?: any) => UiHelper.Translate(home, key, subs);
    const patient = home.patient;
    const { dashboardBeg, dashboardEnd, dashboardHasReferrer, insightId } = props?.match?.params ?? {};

    const handleScroll = (event: any) =>
        NavigationHelper.HandleScroll(
            undefined,
            scrollActive1,
            scrollActive2,
            setScrollActive1,
            setScrollActive2,
            event?.target?.scrollTop
        );

    const armEventReadings = ({
        anchorEvent,
        contributingEventId,
        contributingEventBeg,
        contributingEventEnd,
    }: any) => {
        dispatch(
            contributingEventMetaDataActionCreator({
                contributingEventId,
                contributingEventBeg,
                contributingEventEnd,
            })
        );
        dispatch(
            intersessionDataSaveActionCreator({
                keys: [
                    ConstantsHelper.IntersessionKeys.contributingEventId,
                    ConstantsHelper.IntersessionKeys.contributingEventBeg,
                    ConstantsHelper.IntersessionKeys.contributingEventEnd,
                ],
            })
        );
        dispatch(anchorEventActionCreator({ anchorEvent }));
    };

    const setFeedback = (payload: IInsightFeedback) => dispatch(sendFeedback(payload));

    useEffect(() => {
        /* istanbul ignore next: no else condition */
        if (
            !(
                home.dashboardBeg === dashboardBeg &&
                home.dashboardEnd === dashboardEnd &&
                home.dashboardHasReferrer === (dashboardHasReferrer === 'true')
            )
        ) {
            dispatch(
                dashboardMetaDataActionCreator({
                    dashboardBeg,
                    dashboardEnd,
                    dashboardHasReferrer: dashboardHasReferrer === 'true',
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardBeg, dashboardEnd, dashboardHasReferrer]);

    useEffect(() => {
        if (home.insightId !== insightId) {
            dispatch(insightIdActionCreator({ insightId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insightId]);

    const dashboard = home.currentDashboard;
    const insight = home.cachedInsights[home.insightId];
    const clearAnchorEvent = () => dispatch(anchorEventActionCreator({ anchorEvent: undefined }));
    const [scrollActive1, setScrollActive1] = useState(false);
    const [scrollActive2, setScrollActive2] = useState(false);

    useLayoutEffect(() => {
        if (insight?.insightId && insight?.insightType && insight?.patternType) {
            SystemHelper.AppInsightsEvent(home, 'InsightDetails()', 'Mounted');
            sendAzureEvent(ConstantsHelper.analyticsEventNames.VIEW_INSIGHT_DETAILS, {
                insightId: insight?.insightId,
                insightType: insight?.insightType,
                patternType: insight?.patternType,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (patient.receivedData && !(home.dashboardEnd && home.insightId && dashboard?.insightIds)) {
            const payload = UiHelper.GetInsightsDateRange(dispatch, home);

            if (payload.beg && payload.end) {
                UiHelper.ClearErrors(dispatch);
                UiHelper.FetchDashboard(dispatch, payload.beg, payload.end, home);
                UiHelper.FetchDashboardInsulinUsage(dispatch, payload.beg, payload.end, home);
                UiHelper.FetchDashboardInsulinUsageTrend(dispatch, payload.beg, payload.end, home);
                UiHelper.FetchDashboardSummary(dispatch, payload.beg, payload.end, home);
                UiHelper.FetchDashboardSummaryGlucoseTrend(dispatch, payload.beg, payload.end, home);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        patient.receivedData,
        home.control?.pauseItEnabled,
        home.patient?.confidentialityAgreementAccepted,
        home.patient?.eulaAccepted,
    ]);

    useEffect(() => {
        if (home.anchorEvent && insight) {
            NavigationHelper.ScrollIntoView(home.anchorEvent, clearAnchorEvent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [home.anchorEvent, insight]);

    const insightAttributes = InsightHelper.GetInsightAttributes(home, insight, translate);

    return (
        <ContentWrapperAll home={home} dispatch={dispatch} showIfInvalidEnrollment={false} translate={translate}>
            {dashboard?.insightIds && (
                <div className={styleGeneral.body} onScroll={(event) => handleScroll(event)} ref={bodyRef}>
                    <div className={styleGeneral.stickToTopBack}>
                        <BtnBack
                            url={NavigationHelper.GenUrlDashboard(
                                home.dashboardBeg,
                                home.dashboardEnd,
                                home.dashboardHasReferrer
                            )}
                        />
                    </div>

                    <div className={styleGeneral.insightEntryDetail}>
                        <InsightDetail
                            cbClickEventFn={armEventReadings}
                            home={home}
                            translate={translate}
                            insight={insight}
                            insightAttributes={insightAttributes}
                            parentScrollNode={bodyRef.current}
                            scrollActive1={scrollActive1}
                        />
                    </div>

                    <div className={styleGeneral.insightEntryFooter}>
                        <InsightEdNotes notes={insightAttributes.edNotes} translate={translate} />

                        <InsightUserFeedback
                            home={home}
                            cbClickFeedbackFn={(params: IInsightFeedback) => setFeedback(params)}
                            insight={insight}
                            translate={translate}
                        />

                        <InsightEdWarning notes={insightAttributes.edNotes} translate={translate} />
                    </div>
                </div>
            )}
        </ContentWrapperAll>
    );
}

export default InsightDetails;
