/**
 * Gibraltar API for PWD
 * API for presenting Gibraltar patient data.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: azheng@insulet.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FeedbackValue } from './feedbackValue';

/**
 * User feedback
 */
export interface InsightFeedback extends FeedbackValue { 
    insightId?: string;
}
export namespace InsightFeedback {
}