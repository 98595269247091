import clsx from 'clsx';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ICallback } from '../../types';
import styleGuide from '../../styles/styleGuide.module.scss';

function BtnPrimary({
    cbClickFn,
    payload,
    url,
    caption,
    focus,
    disabled,
}: {
    cbClickFn: ICallback<void>;
    payload?: any;
    url?: string;
    caption: string | JSX.Element;
    focus?: boolean;
    disabled?: boolean;
}) {
    const anchorRef: React.RefObject<HTMLAnchorElement> = React.createRef<HTMLAnchorElement>();

    useEffect(() => {
        if (focus && anchorRef?.current) {
            anchorRef.current.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anchorRef]);

    return (
        <Link
            data-testid={'testPrimaryBtn'}
            innerRef={anchorRef}
            className={clsx(styleGuide.btnPrimary, disabled && styleGuide.btnDisabled)}
            to={url ?? '#'}
            replace
            onClick={() => (cbClickFn && !disabled ? cbClickFn(payload) : undefined)}
        >
            <span className={styleGuide.default}>{caption}</span>
        </Link>
    );
}

export default BtnPrimary;
