import React from 'react';

import ConstantsHelper from '../../helpers/ConstantsHelper';

function BarAxisBottomTick({ value, x, y }: { value: number; x: number; y: number }) {
    if (value <= 0) {
        return null;
    }

    const vectorLineColor = ConstantsHelper.Chart.colorTickMark;
    const vectorLineWidth = 1.5;
    const vectorLineYEnd = 8;
    const vectorLineYStart = 0;
    const moveGraphicFurtherRight = 4;

    return (
        <g data-testId={`custom_tick--${value}`} transform={`translate(${x},${y + moveGraphicFurtherRight})`}>
            <line stroke={vectorLineColor} strokeWidth={vectorLineWidth} y1={vectorLineYStart} y2={vectorLineYEnd} />
        </g>
    );
}

export default BarAxisBottomTick;
