/**
 * Gibraltar API for PWD
 * API for presenting Gibraltar patient data.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: azheng@insulet.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { InsightInsightsProp } from './insightInsightsProp';

export interface InsightTimeOfDayInsightsPropInner extends InsightInsightsProp {
    /**
     * Insight events overlap start time in HH:MM:SS format
     */
    overlapStartTime?: string;
    /**
     * Insight events overlap end time in HH:MM:SS format
     */
    overlapEndTime?: string;
    /**
     * Weight value assigned by algorithm to this insight.
     */
    weight?: number;
}
export namespace InsightTimeOfDayInsightsPropInner {}
