/**
 * Gibraltar API for PWD
 * API for presenting Gibraltar patient data.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: azheng@insulet.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { InsightInsightsProp } from './insightInsightsProp';
import { InsightTimeOfDayInsightsProp } from './insightTimeOfDayInsightsProp';
import { PatternType } from './patternType';

/**
 * Insight Element collection of insights array and timeOfDayInsights array.
 */
export interface InsightElement extends PatternType { 
    insights?: Array<InsightInsightsProp>;
    timeOfDayInsights?: Array<InsightTimeOfDayInsightsProp>;
}
export namespace InsightElement {
}