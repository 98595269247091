/**
 * Gibraltar API for PWD
 * API for presenting Gibraltar patient data.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: azheng@insulet.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Pattern type information.
 */
export interface PatternType { 
    patternType?: PatternType.PatternTypeEnum;
}
export namespace PatternType {
    export type PatternTypeEnum = 'high' | 'low';
    export const PatternTypeEnum = {
        High: 'high' as PatternTypeEnum,
        Low: 'low' as PatternTypeEnum
    };
}