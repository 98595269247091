import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import { appInsights } from '../helpers/appInsights';

type PropTypes = {
    after: (aiObject: any) => void;
    initialized?: boolean;
};

type StateTypes = {
    initialized: boolean;
};

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component<PropTypes, StateTypes> {
    static defaultProps: Pick<PropTypes, 'initialized'> = {
        initialized: false,
    };

    constructor(props: PropTypes | Readonly<PropTypes>) {
        super(props);

        this.state = {
            initialized: Boolean(props.initialized),
        };
    }

    componentDidMount() {
        const { initialized } = this.state;

        if (!initialized) {
            this.setState({ initialized: true });
        }

        (this.props as any).after(appInsights);
    }

    render() {
        const { children } = this.props;

        return <Fragment>{children}</Fragment>;
    }
}

export default withRouter(TelemetryProvider as any);
