/**
 * Gibraltar API for PWD
 * API for presenting Gibraltar patient data.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: azheng@insulet.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Basal submodes
 */
export interface Submode { 
    /**
     * Pod detected submode
     */
    value?: Submode.ValueEnum;
    /**
     * ISO 8601 date of submode start
     */
    startDate?: string;
    /**
     * ISO 8601 date of submode end
     */
    endDate?: string;
}
export namespace Submode {
    export type ValueEnum = 'InsulinPaused' | 'InsulinMax' | 'BasalMode';
    export const ValueEnum = {
        InsulinPaused: 'InsulinPaused' as ValueEnum,
        InsulinMax: 'InsulinMax' as ValueEnum,
        BasalMode: 'BasalMode' as ValueEnum
    };
}