import React from 'react';
import clsx from 'clsx';

import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import { IHome, ITranslator } from '../../../types';
import BtnPrimary from '../../common/btnPrimary';
import NavigationHelper from '../../../helpers/NavigationHelper';
import StringHelper from '../../../helpers/StringHelper';

const anchor = 'congratsAnchor';

function LoyaltyCongrats({
    home,
    translate,
    cbLoyaltyCongrats,
}: {
    home: IHome;
    translate: ITranslator;
    cbLoyaltyCongrats?: any;
}) {
    if (!home?.currentLearningMaterialProgress?.data?.weekNumber) {
        return null;
    }

    return (
        <div data-testid="loyalty_congrats" id={anchor} key={anchor} className={styleGuide.InsightCard}>
            <div className={styleGuide.whiteBackgroundAlt}>
                <div className={styleGuide.headerLoyalty}>
                    <div className={styleGuide.loyaltyCongrats}>
                        <div className={styleGeneral.table}>
                            <div className={styleGeneral.row}>
                                <div className={styleGuide.loyaltyRow}>
                                    <div className={clsx(styleGeneral.cell, styleGuide.loyaltyLeft)}>
                                        <div className={styleGeneral.table}>
                                            <div className={styleGeneral.row}>
                                                <div className={clsx(styleGeneral.cell, styleGuide.titleAlt1)}>
                                                    <div>{home.currentLearningMaterialProgress?.data?.title}</div>
                                                </div>
                                            </div>
                                            <div className={styleGeneral.row}>
                                                <div className={clsx(styleGeneral.cell, styleGuide.titleAlt2)}>
                                                    <div>{translate('loyalty.congrats.youCompleted')}</div>
                                                </div>
                                                <div
                                                    className={clsx(
                                                        styleGeneral.cell,
                                                        home.currentLearningMaterialProgress?.data?.weekNumber > 99
                                                            ? styleGuide.titleAlt4
                                                            : styleGuide.titleAlt3
                                                    )}
                                                >
                                                    <div>
                                                        {translate('loyalty.congrats.week', {
                                                            weekNumber: StringHelper.NumberFormat(
                                                                home.currentLearningMaterialProgress?.data?.weekNumber,
                                                                false,
                                                                true
                                                            ),
                                                        })}
                                                    </div>
                                                </div>
                                                <div className={clsx(styleGeneral.cell, styleGuide.titleAlt2)}>
                                                    <div>{translate('loyalty.congrats.onOmnipod5')}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={clsx(styleGeneral.cell, styleGuide.loyaltyRight)}>
                                        <img
                                            className={styleGuide.congratsLogo}
                                            src={home.currentLearningMaterialProgress?.data?.featuredImage}
                                            alt={translate('loyalty.congrats.therapy.imgText')}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styleGeneral.row}>
                                <div className={styleGuide.loyaltyRow}>
                                    <span className={styleGeneral.cell}>
                                        <span className={clsx(styleGuide.loyaltyBlock)}>
                                            {home.currentLearningMaterialProgress?.data?.body}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <BtnPrimary
                                data-testid={`anchor_${anchor}`}
                                caption={translate('viewResources')}
                                cbClickFn={() => cbLoyaltyCongrats(anchor)}
                                url={NavigationHelper.GenUrlCongratsInsights(
                                    home.dashboardBeg,
                                    home.dashboardEnd,
                                    home.dashboardHasReferrer
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoyaltyCongrats;
