import React from 'react';
import clsx from 'clsx';

import ConstantsHelper from '../../helpers/ConstantsHelper';
import DateTimeHelper from '../../helpers/DateTimeHelper';
import { InsightInsightsProp, PatternType } from '../../model/models';
import iconHigh from '../../assets/images/event-high.svg';
import iconLow from '../../assets/images/event-low.svg';
import styleGuide from '../../styles/styleGuide.module.scss';
import { IContributingEvent, IDataUnits, IHome, ITranslator } from '../../types';
import EventHelper from '../../helpers/EventHelper';

function PlankEvent({
    home,
    translate,
    units,
    delta,
    event,
    patternType,
}: {
    home: IHome;
    translate: ITranslator;
    units: IDataUnits;
    delta: number;
    event: IContributingEvent;
    patternType: InsightInsightsProp.PatternTypeEnum;
}) {
    const time = DateTimeHelper.FormatTimeStamp(event?.beg, false, undefined, undefined, true);
    const durationValue = event?.duration ?? 0;
    const duration = DateTimeHelper.FormatTimeDelta(home, durationValue, undefined, true);
    const deltaCaption =
        delta < 0 ? '' : `${DateTimeHelper.FormatTimeDelta(home, delta, undefined, true)} ${translate('later')}`;
    const adjunctData = { icon: '', alt: '', class: '', tag: '' };

    switch (patternType) {
        case PatternType.PatternTypeEnum.Low:
            adjunctData.alt = translate(ConstantsHelper.ReadingsLabelLow);
            adjunctData.icon = iconLow;
            adjunctData.class = styleGuide.ovalL;
            adjunctData.tag = translate('lowest');
            break;
        case PatternType.PatternTypeEnum.High:
        default:
            adjunctData.alt = translate(ConstantsHelper.ReadingsLabelHigh);
            adjunctData.icon = iconHigh;
            adjunctData.class = styleGuide.ovalH;
            adjunctData.tag = translate('highest');
            break;
    }

    return (
        event && (
            <>
                {deltaCaption.length > 0 && (
                    <div id="plankDelta" className={styleGuide.plankDelta}>
                        <div className={styleGuide.content}>{deltaCaption}</div>
                    </div>
                )}
                <div className={styleGuide.plankEvent}>
                    <div className={styleGuide.title}>
                        <img
                            src={adjunctData.icon}
                            className={clsx(styleGuide.icon, adjunctData.class)}
                            alt={`${adjunctData.alt} icon`}
                        />
                        {adjunctData.alt} {translate('event')}
                    </div>
                    <div className={styleGuide.body}>
                        <span className={styleGuide.detailLeft}>
                            {translate('time')}: {time}
                        </span>
                        <span className={styleGuide.detail}>
                            {translate('duration')}: {duration}
                        </span>
                    </div>
                    <div className={styleGuide.body}>
                        <span className={styleGuide.detailLeft}>
                            {adjunctData.tag}: {EventHelper.GetEgvLabel(translate, event.extremeBGValue, false)}{' '}
                            {units?.egv}
                        </span>
                    </div>
                </div>
            </>
        )
    );
}

export default PlankEvent;
