/**
 * Gibraltar API for PWD
 * API for presenting Gibraltar patient data.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: azheng@insulet.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Basal modes
 */
export interface Mode { 
    /**
     * General mode of pod
     */
    value?: Mode.ValueEnum;
    /**
     * ISO 8601 date of mode start
     */
    startDate?: string;
    /**
     * ISO 8601 date of mode end
     */
    endDate?: string;
}
export namespace Mode {
    export type ValueEnum = 'Manual' | 'Automated' | 'Limited' | 'Activity';
    export const ValueEnum = {
        Manual: 'Manual' as ValueEnum,
        Automated: 'Automated' as ValueEnum,
        Limited: 'Limited' as ValueEnum,
        Activity: 'Activity' as ValueEnum
    };
}