import UtilityHelper from './UtilityHelper';

import { ICallback, IContributingEventNav, IRectangleSize } from '../types';

export default class NavigationHelper {
    public static ElementExists = (anchor: string) => document.querySelector(`#${anchor}`);

    public static GetElementDimensions = (elemId: string): IRectangleSize => {
        const rectangleSize: IRectangleSize = { height: 0, width: 0 };

        if (elemId) {
            const elem = NavigationHelper.ElementExists(elemId);

            if (elem) {
                rectangleSize.height = elem.clientHeight;
                rectangleSize.width = elem.clientWidth;
            }
        }

        return rectangleSize;
    };

    public static ScrollIntoView = (anchor: string, cbFn: ICallback<void> = null) => {
        if (anchor) {
            const elem = NavigationHelper.ElementExists(anchor);

            if (elem) {
                const hasAnchor = elem.getElementsByTagName('A')?.length > 0;

                if (hasAnchor) {
                    elem.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
                }

                if (cbFn) {
                    cbFn();
                }
            }
        }
    };

    public static ScrollElement = (id: string, increment: number) => {
        if (id) {
            const elem: HTMLElement = document.querySelector(`#${id}`);

            if (elem) {
                const rectChild = elem.getBoundingClientRect();

                window.scrollTo({
                    top: rectChild.top + increment,
                    behavior: 'smooth',
                });
            }
        }
    };

    public static ElementKeepInbound = (id: string, idContainer: string, maxRetries = 10) => {
        setTimeout(() => {
            const elemChild: HTMLElement = document.querySelector(`#${id}`);
            const elementParent: HTMLElement = document.querySelector(`#${idContainer}`);

            if (elemChild && elementParent) {
                const rectChild = elemChild.getBoundingClientRect();
                const rectParent = elementParent.getBoundingClientRect();
                let left = rectChild.left;
                let top = rectChild.top;

                if (rectChild.right > rectParent.right) {
                    left -= rectChild.right - rectParent.right;
                }
                if (left < rectParent.left) {
                    left = rectParent.left;
                }
                if (rectChild.bottom > rectParent.bottom) {
                    top -= rectChild.bottom - rectParent.bottom;
                }
                if (top < rectParent.top) {
                    top = rectParent.top;
                }

                elemChild.style.left = `${left}px`;
                elemChild.style.top = `${top}px`;
            } else if (maxRetries > 0) {
                setTimeout(() => {
                    NavigationHelper.ElementKeepInbound(id, idContainer, --maxRetries);
                }, 250);
            }
        }, 250);
    };

    public static HandleScroll = (
        pass2: number,
        scrollActive1: boolean,
        scrollActive2: boolean,
        setScrollActive1: any,
        setScrollActive2: any,
        scrollTop = 0
    ) => {
        setScrollActive1(scrollTop > 0);

        if (setScrollActive2 && pass2) {
            setScrollActive2(scrollActive1 && scrollTop > pass2);
        }
    };

    private static genUrl = (
        prefix: string,
        dashboardBeg?: string,
        dashboardEnd?: string,
        dashboardHasReferrer?: boolean,
        insightId?: string,
        contributingEventValue?: IContributingEventNav
    ): string =>
        [
            prefix,
            dashboardBeg,
            dashboardEnd,
            dashboardHasReferrer,
            insightId,
            contributingEventValue?.contributingEventId,
            contributingEventValue?.contributingEventBeg,
            contributingEventValue?.contributingEventEnd,
        ]
            .filter((e) => !UtilityHelper.IsNull(e))
            .join('/');

    public static GenUrlDashboard = (
        dashboardBeg: string,
        dashboardEnd: string,
        dashboardHasReferrer: boolean
    ): string => NavigationHelper.genUrl('/week', dashboardBeg, dashboardEnd, dashboardHasReferrer);

    public static GenUrlCongratsInsights = (
        dashboardBeg: string,
        dashboardEnd: string,
        dashboardHasReferrer: boolean
    ): string => NavigationHelper.genUrl('/congrats', dashboardBeg, dashboardEnd, dashboardHasReferrer);

    public static GenUrlInsulinUsageInsights = (
        dashboardBeg: string,
        dashboardEnd: string,
        dashboardHasReferrer: boolean
    ): string => NavigationHelper.genUrl('/insulin', dashboardBeg, dashboardEnd, dashboardHasReferrer);

    public static GenUrlInsight = (
        dashboardBeg: string,
        dashboardEnd: string,
        dashboardHasReferrer: boolean,
        insightId: string
    ): string => NavigationHelper.genUrl('/insight', dashboardBeg, dashboardEnd, dashboardHasReferrer, insightId);

    public static GenUrlEvent = (
        dashboardBeg: string,
        dashboardEnd: string,
        dashboardHasReferrer: boolean,
        insightId: string,
        contributingEventId: string,
        contributingEventBeg: string,
        contributingEventEnd: string
    ): string =>
        NavigationHelper.genUrl('/event', dashboardBeg, dashboardEnd, dashboardHasReferrer, insightId, {
            contributingEventId: contributingEventId,
            contributingEventBeg: contributingEventBeg,
            contributingEventEnd: contributingEventEnd,
        });
}
