import React from 'react';
import clsx from 'clsx';

import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import { IHome, IInsulinUsage, ITranslator } from '../../../types';
import iconTodNight from '../../../assets/images/avg-daily-tod-night.png';
import iconTodMorning from '../../../assets/images/avg-daily-tod-morning.png';
import iconTodAfternoon from '../../../assets/images/avg-daily-tod-afternoon.png';
import iconTodEvening from '../../../assets/images/avg-daily-tod-evening.png';
import { InsulinUsageAverageMorningNumberMorning } from '../../../model/models';

const getClassToUse = (value: number) => (value ? styleGuide.loyaltyValue : styleGuide.loyaltyValueNull);
const renderTimeRange = (iconSrc: string, label: string, todData: InsulinUsageAverageMorningNumberMorning) => (
    <span className={clsx(styleGeneral.cell, styleGuide.loyaltyCell)}>
        <img src={iconSrc} className={clsx(styleGuide.icon)} alt={label} />
        <span className={styleGuide.loyaltyLabelTop}>{label}</span>
        <span className={styleGuide.loyaltyLabelBottom}>
            {todData?.timeRange?.start ?? ' '} - {todData?.timeRange?.end ?? ' '}
        </span>
        <span className={getClassToUse(todData?.avgNumber)}>{todData?.avgNumber}</span>
    </span>
);

function LoyaltyInsulinUsageAvgDaily({ home, translate }: { home: IHome; translate: ITranslator }) {
    const summary: IInsulinUsage = home?.currentInsulinUsage;

    return !summary?.dailyUsage?.bolusStatistics ? null : (
        <div className={styleGuide.headerLoyalty} data-testid="loyalty_insulin_usage_avg_daily">
            <div className={styleGuide.loyaltyInsulinUsage}>
                <div className={clsx(styleGeneral.table, styleGuide.loyaltyRow2)}>
                    <div className={styleGeneral.row}>
                        <span className={clsx(styleGeneral.cell, styleGuide.loyaltyLeft)}>
                            <span className={styleGuide.avgDailyBolus}>
                                {summary.dailyUsage.bolusStatistics?.avgNumber}
                            </span>
                            <span className={styleGuide.avgDailyBolusLabel}>
                                <span className={styleGuide.inner}>
                                    {translate('loyalty.insulin.avgDailyBolus.bolusesPerDayPart1')}
                                </span>
                                <span className={styleGuide.inner}>
                                    {translate('loyalty.insulin.avgDailyBolus.bolusesPerDayPart2')}
                                </span>
                            </span>
                        </span>
                    </div>
                    <div className={styleGeneral.row}>
                        <span className={clsx(styleGeneral.cell, styleGuide.loyaltyBottomAlt)}>
                            <div className={styleGeneral.table}>
                                <div className={clsx(styleGeneral.row, styleGuide.loyaltyRow)}>
                                    {renderTimeRange(
                                        iconTodNight,
                                        translate('loyalty.insulin.avgDailyBolus.nighttime'),
                                        summary.dailyUsage.bolusStatistics?.timeOfDay?.nighttime
                                    )}
                                    {renderTimeRange(
                                        iconTodMorning,
                                        translate('loyalty.insulin.avgDailyBolus.morning'),
                                        summary.dailyUsage.bolusStatistics?.timeOfDay?.morning
                                    )}
                                    {renderTimeRange(
                                        iconTodAfternoon,
                                        translate('loyalty.insulin.avgDailyBolus.afternoon'),
                                        summary.dailyUsage.bolusStatistics?.timeOfDay?.afternoon
                                    )}
                                    {renderTimeRange(
                                        iconTodEvening,
                                        translate('loyalty.insulin.avgDailyBolus.evening'),
                                        summary.dailyUsage.bolusStatistics?.timeOfDay?.evening
                                    )}
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoyaltyInsulinUsageAvgDaily;
