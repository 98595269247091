import { BarExtendedDatum } from '@nivo/bar';
import React from 'react';

import { ITranslator } from '../../types';
import ConstantsHelper from '../../helpers/ConstantsHelper';
import styleGuide from '../../styles/styleGuide.module.scss';

interface Bar {
    key: string;
    data: BarExtendedDatum;
    x: number;
    y: number;
    width: number;
    height: number;
    color: string;
}

function BarLabel({ bars, translate }: { bars: Bar[]; translate: ITranslator }) {
    const barLabels = bars.map((bar: Bar) => {
        const {
            key,
            width: barWidth,
            x: barX,
            y: barY,
            data: { id, index, value },
        } = bar;

        const isBolus = id === 'bolus';
        const isTimeInRange = id === 'timeInRange';

        if (index <= 0 || (!isBolus && !isTimeInRange)) {
            return null;
        }

        const isValueGreaterThanZero = value > 0;
        const jointTimeInRange = bars.find((b: any) => b.data.id === 'timeInRange' && b.data.index === index);
        const hasNoJointTimeInRangeValue = !jointTimeInRange?.data.value;
        const jointBolus = bars.find((b: any) => b.data.id === 'bolus' && b.data.index === index);
        const hasNoJointBolusValue = !jointBolus?.data.value;

        let textLabel: string;

        if (isValueGreaterThanZero) {
            textLabel = `${value}%`;
        } else if ((isBolus && !hasNoJointTimeInRangeValue) || (isTimeInRange && hasNoJointBolusValue)) {
            textLabel = translate('notAvailable');
        }

        let textClassName;
        let textColor;
        let textXPosition;

        const textPushLeft = isBolus ? 3 : 0;
        const textBottomMargin = -6;

        if (isBolus || (isTimeInRange && hasNoJointBolusValue && hasNoJointTimeInRangeValue)) {
            textClassName = styleGuide.barChartLabelBolus;
            textColor = ConstantsHelper.Chart.colorBarLabelA;
            textXPosition = barX;
        } else {
            textClassName = styleGuide.barChartLabelTimeInRange;
            textColor = ConstantsHelper.Chart.colorBarLabelB;
            textXPosition = barX + barWidth / 2 - textPushLeft;
        }

        return (
            <g key={key}>
                <text
                    className={textClassName}
                    dominantBaseline="middle"
                    fill={textColor}
                    textAnchor="middle"
                    x={textXPosition}
                    y={barY + textBottomMargin}
                >
                    {textLabel}
                </text>
            </g>
        );
    });

    return <>{barLabels}</>;
}

export default BarLabel;
