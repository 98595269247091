/**
 * Gibraltar API for PWD
 * API for presenting Gibraltar patient data.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: azheng@insulet.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ReleaseNotesEntry } from './releaseNotesEntry';

export interface ReleaseNotesJSONDataItem { 
    /**
     * Version info
     */
    version?: string;
    /**
     * Name of deployed branch
     */
    repositoryBranch?: string;
    /**
     * Build number of deployed code
     */
    cICDBuildPipelineID?: string;
    /**
     * ID of release
     */
    cICDReleasePipelineID?: string;
    notes?: Array<ReleaseNotesEntry>;
    storageAccess?: ReleaseNotesJSONDataItem.StorageAccessEnum;
}
export namespace ReleaseNotesJSONDataItem {
    export type StorageAccessEnum = 'ok' | 'failed';
    export const StorageAccessEnum = {
        Ok: 'ok' as StorageAccessEnum,
        Failed: 'failed' as StorageAccessEnum
    };
}