import { createTheme, radioClasses } from '@mui/material';

declare module '@mui/system' {
    interface BreakpointOverrides {
        xl: false;
    }
}

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 389,
            md: 600,
            lg: 1000,
        },
    },
    components: {
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: '#5b34b9', // TODO: Once palette has been defined please use the value from there.
                    [`&.${radioClasses.checked}`]: {
                        color: '#5b34b9', // TODO: Once palette has been defined please use the value from there.
                    },
                },
            },
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
            },
            styleOverrides: {
                tooltip: {
                    backgroundColor: 'black',
                    color: 'white',
                    fontFamily: 'OpenSans',
                    fontSize: '13px',
                    borderRadius: '6px',
                    maxWidth: '320px',
                    maxHeight: '400px',
                },
            },
        },
    },
    spacing: 10,
});

export default theme;
