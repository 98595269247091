import React from 'react';
import clsx from 'clsx';

import styleGeneral from '../../styles/general.module.scss';
import styleGuide from '../../styles/styleGuide.module.scss';
import { IEdNote, ITranslator } from '../../types';

function InsightEdNotes({ notes, translate }: { notes: IEdNote[]; translate: ITranslator }) {
    return (
        <div className={styleGeneral.main}>
            <div className={styleGuide.edNotes}>
                <div className={clsx(styleGuide.factorsToConsider)}>{translate('factorsToConsider')}</div>
                <div className={styleGeneral.table}>
                    {notes
                        ?.filter((note) => !note.isWarning)
                        .map((note, idx) => (
                            <div key={`edNote${idx}`} className={clsx(styleGeneral.row, styleGuide.areYou)}>
                                <span className={clsx(styleGeneral.cell, styleGuide.left)}>
                                    <img src={note.icon} alt={translate(note.alt)} className={styleGuide.icon} />
                                </span>
                                <span className={clsx(styleGeneral.cell, styleGuide.right)}>{note.text}</span>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
}

export default InsightEdNotes;
