import { isEmpty } from 'lodash';
import { Divider } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import TimeOfDayPatternHeader from './timeOfDayPatternHeader';

import { WindowEventObject } from '../../model/windowEventObject';
import InsightHelper from '../../helpers/InsightHelper';
import { ICallback, IHome, IInsight, IInsightAttr, ITranslator } from '../../types';
import StringHelper from '../../helpers/StringHelper';
import EventCell from '../common/eventCell';
import styleGeneral from '../../styles/general.module.scss';
import styleGuide from '../../styles/styleGuide.module.scss';
import NavigationHelper from '../../helpers/NavigationHelper';

function InsightDetail({
    parentScrollNode,
    cbClickEventFn,
    home,
    translate,
    insight,
    insightAttributes,
    scrollActive1,
}: {
    parentScrollNode: React.ReactNode;
    home: IHome;
    translate: ITranslator;
    scrollActive1: boolean;
    cbClickEventFn: ICallback<void>;
    insight: IInsight;
    insightAttributes: IInsightAttr;
}) {
    const homeLocal: IHome = home;

    if (!insight) {
        return null;
    }

    const eventCount = insight?.windowEgvEvent?.length;
    const typeNamePlural: string = StringHelper.Pluralize(eventCount, `${insightAttributes.name} event`, false);
    const eventCountDisplay: string = StringHelper.Pluralize(eventCount);
    const timeOfDayPatterns: IInsight[] = InsightHelper.GetTimeOfDayPatterns(home, insight);

    const renderEvent = (insightId: string, contributingEvent: WindowEventObject, index: number) => (
        <div key={`insightRow${index}`}>
            <EventCell
                home={home}
                translate={translate}
                anchor={`contributingEvent_${index}`}
                cbClickFn={(payload: any) => cbClickEventFn(payload)}
                url={NavigationHelper.GenUrlEvent(
                    homeLocal.dashboardBeg,
                    homeLocal.dashboardEnd,
                    homeLocal.dashboardHasReferrer,
                    InsightHelper.ConvertInsightIdsChildAndParentToSingle(insightId, homeLocal.insightId),
                    contributingEvent.eventId,
                    contributingEvent.beg,
                    contributingEvent.end
                )}
                contributingEvent={contributingEvent}
                insightAttributes={insightAttributes}
                insightType={insight.insightType}
            />
        </div>
    );

    return (
        <div className={styleGuide.insightEntry}>
            <div className={styleGuide.whiteBackground}>
                <div
                    className={clsx(
                        styleGuide.summary,
                        styleGeneral.eventsZoomInScroll1OffScroll2Off,
                        scrollActive1 && styleGeneral.eventsZoomInScroll1OnScroll2Off
                    )}
                >
                    <div
                        className={clsx(
                            styleGuide.header,
                            styleGeneral.toCapitalize,
                            scrollActive1 && styleGeneral.inScroll
                        )}
                    >
                        {insightAttributes.scrn1Title}
                    </div>

                    <div className={clsx(styleGuide.timeRange, scrollActive1 && styleGeneral.inScroll)}>
                        {insightAttributes.scrn2Title}
                    </div>
                </div>

                <div className={styleGuide.detail}>
                    {isEmpty(timeOfDayPatterns) ? (
                        <>
                            <div
                                className={clsx(styleGuide.banner, styleGeneral.eventsZoomInScroll1OffScroll2Off, {
                                    [styleGeneral.eventsZoomInScroll1OnScroll2OnBorderOn]: scrollActive1,
                                })}
                            >
                                <span
                                    className={clsx(
                                        styleGuide.count,
                                        insightAttributes.style,
                                        scrollActive1 && styleGeneral.inScroll
                                    )}
                                >
                                    {eventCountDisplay}
                                </span>

                                <span
                                    className={clsx(
                                        styleGuide.label,
                                        styleGeneral.toCapitalize,
                                        scrollActive1 && styleGeneral.inScroll
                                    )}
                                >
                                    {typeNamePlural}
                                </span>
                            </div>

                            <div className={styleGuide.eventList}>
                                {insight.windowEgvEvent?.map((contributingEvent, index) =>
                                    renderEvent(homeLocal.insightId, contributingEvent, index)
                                )}
                            </div>
                        </>
                    ) : (
                        timeOfDayPatterns.map((pattern, patternIndex) => (
                            <React.Fragment key={`time_of_day_pattern-${patternIndex}`}>
                                {patternIndex >= 1 ? <Divider className={styleGuide.divider} /> : null}

                                <TimeOfDayPatternHeader
                                    insightPattern={pattern}
                                    parentScrollNode={parentScrollNode}
                                    translate={translate}
                                />

                                <div className={clsx(styleGuide.eventList, styleGuide.timeOfDayEventList)}>
                                    {pattern.windowEgvEvent?.map((contributingEvent, eventIndex) =>
                                        renderEvent(pattern.insightId, contributingEvent, eventIndex)
                                    )}
                                </div>
                            </React.Fragment>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
}

export default InsightDetail;
