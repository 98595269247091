import React from 'react';
import clsx from 'clsx';

import InsightCard from './insightCard';

import InsightHelper from '../../helpers/InsightHelper';
import styleGeneral from '../../styles/general.module.scss';
import styleGuide from '../../styles/styleGuide.module.scss';
import { ICallback, IHome, IInsight, ITranslator } from '../../types';

function InsightsByPattern({
    home,
    scrollActive1,
    scrollActive2,
    insights,
    testId,
    cbClickFn,
    translate,
}: {
    home: IHome;
    scrollActive1: boolean;
    scrollActive2: boolean;
    insights: IInsight[];
    testId: string;
    cbClickFn: ICallback<void>;
    translate: ITranslator;
}) {
    const dashBoardZoomCSS =
        scrollActive1 && home.dashboardHasReferrer
            ? styleGeneral.dashboardZoomInScroll1OnScroll2OffAlt
            : styleGeneral.dashboardZoomInScroll1OnScroll2Off;

    return insights?.length > 0 ? (
        <div className={clsx(styleGuide.insights, styleGuide.insightsByPattern)} data-testid="insights-by-pattern">
            <div
                className={clsx(
                    styleGuide.solidBg,
                    styleGuide.header,
                    styleGeneral.dashboardZoomInScroll1OffScroll2Off,
                    dashBoardZoomCSS
                )}
            >
                <div className={clsx(styleGuide.line)}></div>
                <div
                    className={clsx(
                        styleGuide.title,
                        styleGeneral.toCapitalize,
                        scrollActive2 && styleGeneral.inScroll2
                    )}
                >
                    {translate('patternsFound')}
                </div>
            </div>

            {insights.map((insight, idx) => {
                const insightAttributes = InsightHelper.GetInsightAttributes(home, insight, translate);
                const flaggedDay = InsightHelper.GetFlaggedDays(home, insight);
                const key = `insight_${insight.insightId}_${idx}`;

                return (
                    <div key={`${key}_div`} data-testid={`${testId}_${idx}`}>
                        <InsightCard
                            test-id={'insight-card-id'}
                            key={key}
                            home={home}
                            anchor={`${key}_anchor`}
                            cbClickFn={(payload: any) => cbClickFn(payload)}
                            insight={insight}
                            insightAttributes={insightAttributes}
                            flaggedDay={flaggedDay}
                            translate={translate}
                        />
                    </div>
                );
            })}
        </div>
    ) : null;
}

export default InsightsByPattern;
