/**
 * Gibraltar API for PWD
 * API for presenting Gibraltar patient data.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: azheng@insulet.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Time of day type information.
 */
export interface TimeOfDayType { 
    timeOfDay?: TimeOfDayType.TimeOfDayEnum;
}
export namespace TimeOfDayType {
    export type TimeOfDayEnum = 'nighttime' | 'morning' | 'afternoon' | 'evening';
    export const TimeOfDayEnum = {
        Nighttime: 'nighttime' as TimeOfDayEnum,
        Morning: 'morning' as TimeOfDayEnum,
        Afternoon: 'afternoon' as TimeOfDayEnum,
        Evening: 'evening' as TimeOfDayEnum
    };
}