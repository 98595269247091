import React from 'react';
import clsx from 'clsx';

import { IBolusEntry, IDataUnits, IHome, ITranslator } from '../../types';
import DateTimeHelper from '../../helpers/DateTimeHelper';
import iconBolus from '../../assets/images/icons-x-bolus-white.svg';
import styleGuide from '../../styles/styleGuide.module.scss';
import StringHelper from '../../helpers/StringHelper';
import UtilityHelper from '../../helpers/UtilityHelper';
import EventHelper from '../../helpers/EventHelper';

function PlankBolus({
    home,
    translate,
    units,
    delta,
    bolusEntry,
}: {
    home: IHome;
    translate: ITranslator;
    units: IDataUnits;
    delta: number;
    bolusEntry: IBolusEntry;
}) {
    const time = DateTimeHelper.FormatTimeStamp(bolusEntry?.bolusTime, false, undefined, undefined, true);
    const glucose = UtilityHelper.IsNumeric(bolusEntry?.egv)
        ? `${EventHelper.GetEgvLabel(translate, bolusEntry?.egv, false)} ${units?.egv}`
        : translate('notAvailable');
    const carbs = `${bolusEntry?.carbs ?? '--'} ${units.carbs}`;
    const totalUnitsNum = StringHelper.NumberFormatter(UtilityHelper.RoundNumber(bolusEntry?.bolusTotal, 2));
    const totalUnits = `${totalUnitsNum} ${units?.bolus}`;
    const deltaCaption =
        delta < 0 ? '' : `${DateTimeHelper.FormatTimeDelta(home, delta, undefined, true)} ${translate('later')}`;

    return !bolusEntry ? null : (
        <>
            {deltaCaption.length > 0 && (
                <div id="plankDelta" className={styleGuide.plankDelta}>
                    <div className={styleGuide.content}>{deltaCaption}</div>
                </div>
            )}
            <div className={styleGuide.plankBolus}>
                <div className={styleGuide.title}>
                    <img
                        src={iconBolus}
                        className={clsx(styleGuide.icon, styleGuide.ovalBolus)}
                        alt={translate('bolusIcon')}
                    />
                    {translate('bolus')}
                </div>
                <div className={styleGuide.body}>
                    <span className={styleGuide.detailLeft}>
                        {translate('time')}: {time}
                    </span>
                    <span className={styleGuide.detail}>
                        {translate('carbs')}: {carbs}
                    </span>
                </div>
                <div className={styleGuide.body}>
                    <span className={styleGuide.detailLeft}>
                        {translate('glucose')}: {glucose}
                    </span>
                    <span className={styleGuide.detail}>
                        {translate('totalUnits')}: {totalUnits}
                    </span>
                </div>
            </div>
        </>
    );
}

export default PlankBolus;
