import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import ConstantsHelper from 'src/helpers/ConstantsHelper';
import { errorActionCreator } from 'src/store/home';
import { IError, ITranslator } from 'src/types';

import UserNotAssignedToApplicationError from './UserNotAssignedToApplicationError';

function LoginError({ error, translate }: { error: Error; translate: ITranslator }) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            errorActionCreator({
                key: ConstantsHelper.LoginCallbackErrors.key,
                error: {
                    level: ConstantsHelper.ErrorLevels.error,
                    message: error.message || error,
                    silent: true,
                    title: ConstantsHelper.LoginCallbackErrors.title,
                } as IError,
            })
        );
    }, [dispatch, error]);

    if (error.message === ConstantsHelper.oktaLoginCallbackErrors.userNotAssignedToApplication) {
        return <UserNotAssignedToApplicationError translate={translate} />;
    }

    return (
        <div data-testid="login-error">
            {error.name && error.message
                ? `${error.name}: ${error.message}`
                : `${translate('loginError.error')}: ${error}`}
        </div>
    );
}

export default LoginError;
