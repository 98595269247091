import { Paper } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import clsx from 'clsx';
import React, { useEffect } from 'react';

import InsightSummaryDaysInRange from './InsightSummaryDaysInRange';
import InsightSummaryTimeInRange from './InsightSummaryTimesInRange';
import InsightSummaryGlucoseTrends from './InsightSummaryGlucoseTrends';

import { IHome, IInsight3, ITranslator } from '../../../types';
import { Insight3 } from '../../../model/insight3';
import styleGuide from '../../../styles/styleGuide.module.scss';
import styleGeneral from '../../../styles/general.module.scss';
import DateTimeHelper from '../../../helpers/DateTimeHelper';
import SystemHelper from '../../../helpers/SystemHelper';
import { Insight3GlucoseTrend } from '../../../model/models';
import UtilityHelper from '../../../helpers/UtilityHelper';

function InsightSummaryCarousel({
    home,
    translate,
    scrollActive2,
    summary,
    summaryGlucoseTrend,
    setIndex,
}: {
    home: IHome;
    translate: ITranslator;
    scrollActive2: boolean;
    summary: Insight3;
    summaryGlucoseTrend: Insight3GlucoseTrend;
    setIndex: any;
}) {
    useEffect(() => {
        SystemHelper.AppInsightsInfo(home, 'Dashboard', 'Debug', ' Summary');
    }, [home]);

    const summaryFinal: IInsight3 = {
        ...summary,
        activePct: 89,
        activePctDelta: -3,
        avgUnitsPerDay: 17,
        avgUnitsPerDayBasal: 6,
        avgUnitsPerDayBolus: 14,
        avgUnitsPerDayDelta: 11,
        timeInModeAutoPct: 64,
        timeInModeAutoPctDelta: 3,
        timeInRangePctDelta: summaryGlucoseTrend?.previousWeekComparison?.differenceInRangeValue,
        timeInModeLimitedPct: 3,
        timeInModeManualPct: 33,
        timeInRangePct: summaryGlucoseTrend?.currentWeekGlucoseTrend?.inRange,
        timeInRangeHighPct: summaryGlucoseTrend?.currentWeekGlucoseTrend?.high,
        timeInRangeLowPct: summaryGlucoseTrend?.currentWeekGlucoseTrend?.low,
        prevWeekLabel: DateTimeHelper.FormatDateRange(
            DateTimeHelper.GetIsoWeekRange(home.dashboardEnd, -7)?.end,
            undefined,
            true
        ),
    };

    const items = [
        {
            name: translate('daysInRange'),
            component: InsightSummaryDaysInRange,
            show: summary?.celebrationDays?.filter((d) => d.percentage >= summary.goodPctThreshold).length > 0,
        },
        {
            name: translate('timeInRange'),
            component: InsightSummaryTimeInRange,
            show:
                summaryGlucoseTrend?.currentWeekGlucoseTrend &&
                (!UtilityHelper.IsNull(summaryGlucoseTrend?.currentWeekGlucoseTrend.inRange) ||
                    !UtilityHelper.IsNull(summaryGlucoseTrend?.currentWeekGlucoseTrend.high) ||
                    !UtilityHelper.IsNull(summaryGlucoseTrend?.currentWeekGlucoseTrend.low)),
        },
        {
            name: translate('glucoseTrends'),
            component: InsightSummaryGlucoseTrends,
            show:
                summaryGlucoseTrend?.currentWeekGlucoseTrend &&
                !UtilityHelper.IsNull(summaryGlucoseTrend?.previousWeekComparison?.differenceInRangeValue),
        },
    ];

    const itemsToShow = items.filter((e) => e.show);
    const showNavCtls = itemsToShow.length > 1;
    const showItems = itemsToShow.length > 0;

    return !showItems ? null : (
        <div className={clsx(styleGuide.insights, styleGeneral.dashboardSummary)} key="patternSummaryOverall">
            <div className={clsx(styleGuide.solidBg, styleGuide.header)}>
                <div
                    className={clsx(
                        styleGuide.title,
                        styleGeneral.toCapitalize,
                        scrollActive2 && styleGeneral.inScroll2
                    )}
                >
                    {translate('summary')}
                </div>
            </div>

            <div className={styleGuide.InsightCard} data-testid={'carouselTestId'}>
                <Carousel
                    autoPlay={false}
                    animation={'slide'}
                    className={styleGuide.carousel}
                    index={home.carouselIndex}
                    indicators={showNavCtls}
                    reverseEdgeAnimationDirection={false}
                    cycleNavigation={false}
                    navButtonsAlwaysVisible={showNavCtls}
                    navButtonsProps={{
                        className: styleGuide.navButtons,
                    }}
                    activeIndicatorIconButtonProps={{
                        className: styleGuide.carouselIndicator,
                    }}
                    onChange={(evt: any) => setIndex({ index: evt })}
                >
                    {itemsToShow.map((item, idx) => (
                        <Paper key={`slide${idx}`} className={styleGuide.item}>
                            {item.component({ home, summary: summaryFinal, translate: translate })}
                        </Paper>
                    ))}
                </Carousel>
            </div>
        </div>
    );
}

export default InsightSummaryCarousel;
