import * as en from '../i18n/locales/en.json';

export default class AnI18NextLibHelper {
    public static loadLang = (lang: string) => {
        let anI18Nextlib: any = en;

        if (lang !== 'en') {
            anI18Nextlib = en;
        }

        return anI18Nextlib['default'];
    };

    private static translate = (dict: any, keys: string[]): string => {
        let output = '';

        if (dict && typeof dict === 'object' && keys?.length > 0) {
            const val = dict[keys[0]];

            if (val && typeof val === 'object' && keys?.length > 1) {
                output = AnI18NextLibHelper.translate(val, keys.slice(1));
            } else {
                output = val;
            }
        }

        return output;
    };

    public static Translate = (dictOrig: any, key: string, subs?: any): string => {
        let output = AnI18NextLibHelper.translate(dictOrig ?? AnI18NextLibHelper.loadLang(''), key?.split('.'));

        if (output && subs) {
            for (const fld in subs) {
                // Note:  replaceAll() break PR pipeline
                // output = output.replaceAll(token, `${subs[fld]}`);

                const token = `{{${fld}}}`;

                while (output.indexOf(token) > -1) {
                    output = output.replace(token, `${subs[fld]}`);
                }
            }
        }

        return output;
    };
}
