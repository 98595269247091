import React from 'react';
import clsx from 'clsx';

import LoyaltyCongrats from './loyaltyCongrats';
import LoyaltyInsulinModeUsage from './LoyaltyInsulinModeUsage';

import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import { IHome, ITranslator } from '../../../types';

function LoyaltyFrame({
    home,
    translate,
    scrollActive2,
    cbLoyaltyCongrats,
    cbLoyaltyInsulin,
}: {
    home: IHome;
    translate: ITranslator;
    scrollActive2: any;
    cbLoyaltyCongrats?: any;
    cbLoyaltyInsulin?: any;
}) {
    // Skip both cards if there's no content to show
    return !(
        home?.currentLearningMaterialProgress?.data?.weekNumber || home?.currentInsulinUsage?.dailyUsage?.insulinRatio
    ) ? null : (
        <div className={styleGuide.insights} key="patternLoyaltyOverall">
            <div className={clsx(styleGuide.solidBg, styleGuide.header)}>
                <div className={clsx(styleGuide.line)}></div>
                <div
                    className={clsx(
                        styleGuide.title,
                        styleGeneral.toCapitalize,
                        scrollActive2 && styleGeneral.inScroll2
                    )}
                >
                    {translate('loyalty.headerA')}
                    <sup>&#174;</sup>&nbsp;{translate('loyalty.headerB')}
                </div>
            </div>
            {!home.patient?.isOnboarded && (
                <LoyaltyCongrats home={home} translate={translate} cbLoyaltyCongrats={cbLoyaltyCongrats} />
            )}
            <LoyaltyInsulinModeUsage home={home} translate={translate} cbLoyaltyInsulin={cbLoyaltyInsulin} />
        </div>
    );
}

export default LoyaltyFrame;
