/**
 * Gibraltar API for PWD
 * API for presenting Gibraltar patient data.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: azheng@insulet.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Feedback object.
 */
export interface FeedbackValue { 
    /**
     * Feedback value -- 0/null=not set, 1=yes, 2=no
     */
    feedbackValue?: FeedbackValue.FeedbackValueEnum;
}
export namespace FeedbackValue {
    export type FeedbackValueEnum = '0' | '1' | '2';
    export const FeedbackValueEnum = {
        _0: '0' as FeedbackValueEnum,
        _1: '1' as FeedbackValueEnum,
        _2: '2' as FeedbackValueEnum
    };
}