import { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';

function Login(): null {
    const { oktaAuth } = useOktaAuth();
    useEffect(() => {
        oktaAuth.signInWithRedirect({ originalUri: '/' }).catch(() => {
            window.location.assign('/');
        });
        // eslint-disable-next-line
    }, []);

    return null;
}

export default Login;
