import React from 'react';
import clsx from 'clsx';

import styleGuide from '../../styles/styleGuide.module.scss';
import { ICallback } from '../../types';

function BtnSingle({
    cbClickFn,
    caption,
    isActive,
}: {
    cbClickFn?: ICallback<void>;
    caption?: string;
    isActive?: boolean;
}) {
    return (
        <input
            type="button"
            className={clsx(styleGuide.btnSingle, isActive === true ? styleGuide.btnSingleActive : undefined)}
            onClick={(e) => {
                e.preventDefault();
                cbClickFn();
            }}
            value={caption}
        />
    );
}

export default BtnSingle;
