import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { sendAzureEvent } from 'src/helpers/appInsights';

import SystemHelper from '../helpers/SystemHelper';
import ContributingEventDetail from '../components/contributingEvent/ContributingEventDetail';
import styleGeneral from '../styles/general.module.scss';
import { IControl, IState } from '../types';
import BtnBack from '../components/common/btnBack';
import UiHelper from '../helpers/UiHelper';
import NavigationHelper from '../helpers/NavigationHelper';
import {
    contributingEventMetaDataActionCreator,
    dashboardMetaDataActionCreator,
    homeInitialState,
    insightIdActionCreator,
} from '../store/home';
import ConstantsHelper from '../helpers/ConstantsHelper';
import ContentWrapperAll from '../components/common/contentWrappers/ContentWrapperAll';
import InsightHelper from '../helpers/InsightHelper';

function ContributingEvent(props: any) {
    const dispatch = useDispatch();
    const home = SystemHelper.GetCleanState(
        useSelector((state: IState) => state.home),
        homeInitialState
    );
    const translate = (key: string, subs?: any) => UiHelper.Translate(home, key, subs);
    const control = home.control;
    const windowSize = control.windowSize;
    const {
        dashboardBeg,
        dashboardEnd,
        dashboardHasReferrer,
        insightId,
        contributingEventId,
        contributingEventBeg,
        contributingEventEnd,
    } = props?.match?.params ?? {};
    /* istanbul ignore next: Jest dom doesnt provide actual rendering capability so unable to get scroll values */
    const handleScroll = (event: any) =>
        NavigationHelper.HandleScroll(
            undefined,
            scrollActive1,
            undefined,
            setScrollActive1,
            undefined,
            event?.target?.scrollTop
        );

    useEffect(() => {
        if (
            !(
                home.dashboardBeg === dashboardBeg &&
                home.dashboardEnd === dashboardEnd &&
                home.dashboardHasReferrer === (dashboardHasReferrer === 'true')
            )
        ) {
            dispatch(
                dashboardMetaDataActionCreator({
                    dashboardBeg,
                    dashboardEnd,
                    dashboardHasReferrer: dashboardHasReferrer === 'true',
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardBeg, dashboardEnd, dashboardHasReferrer]);

    useEffect(() => {
        if (home.insightId !== insightId) {
            dispatch(insightIdActionCreator({ insightId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [insightId]);

    useEffect(() => {
        if (
            !(
                home.contributingEventId === contributingEventId &&
                home.contributingEventBeg === contributingEventBeg &&
                home.contributingEventEnd === contributingEventEnd
            )
        ) {
            dispatch(
                contributingEventMetaDataActionCreator({
                    contributingEventId,
                    contributingEventBeg,
                    contributingEventEnd,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contributingEventId, contributingEventBeg, contributingEventEnd]);

    const setControls = (control1: Partial<IControl>, keys?: string[]) =>
        UiHelper.SetControlProps(dispatch, control1, keys);
    const dashboard = home.currentDashboard;
    const insight = home.cachedInsights[InsightHelper.ConvertInsightIdSingleToChildAndParent(home.insightId).child];
    const insightEvent = insight?.windowEgvEvent?.find((e) => e.eventId === home.contributingEventId);
    const dataItemReadings = home.currentReadingCombo;
    const [scrollActive1, setScrollActive1] = useState(false);

    useEffect(() => {
        SystemHelper.AppInsightsEvent(home, 'ContributingEvent()', 'Mounted');
        if (insightEvent?.eventId && !home.currentReadingCombo?.fetched) {
            sendAzureEvent(ConstantsHelper.analyticsEventNames.VIEW_EVENT_DETAILS, {
                eventId: home.contributingEventId,
            });
            UiHelper.FetchInsightEventReadings(dispatch, insightId, insightEvent?.eventId, home);
            UiHelper.FetchInsightEventBoluses(dispatch, insightId, insightEvent?.eventId, home);
            UiHelper.FetchInsightEventModes(dispatch, insightId, insightEvent?.eventId, home);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        home,
        insightEvent,
        insightId,
        home.control?.pauseItEnabled,
        home.patient?.confidentialityAgreementAccepted,
        home.patient?.eulaAccepted,
    ]);

    useEffect(() => {
        if (
            home.patient.receivedData &&
            !(
                home.dashboardEnd &&
                InsightHelper.ConvertInsightIdSingleToChildAndParent(home.insightId).child.length &&
                dashboard?.insightIds
            )
        ) {
            const payload = UiHelper.GetInsightsDateRange(dispatch, home);

            if (payload.beg && payload.end) {
                UiHelper.ClearErrors(dispatch);
                UiHelper.FetchDashboard(dispatch, payload.beg, payload.end, home);
                UiHelper.FetchDashboardInsulinUsage(dispatch, payload.beg, payload.end, home);
                UiHelper.FetchDashboardInsulinUsageTrend(dispatch, payload.beg, payload.end, home);
                UiHelper.FetchDashboardSummary(dispatch, payload.beg, payload.end, home);
                UiHelper.FetchDashboardSummaryGlucoseTrend(dispatch, payload.beg, payload.end, home);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        home.patient.receivedData,
        home.control?.pauseItEnabled,
        home.patient?.confidentialityAgreementAccepted,
        home.patient?.eulaAccepted,
    ]);

    if (insight) {
        if (windowSize.width !== 0 && windowSize.height !== 0) {
            SystemHelper.AppInsightsInfo(
                home,
                'Dashboard',
                'Debug',
                `New size is ${windowSize.width}x${windowSize.height}`
            );
        }
    }

    return (
        <ContentWrapperAll home={home} dispatch={dispatch} showIfInvalidEnrollment={false} translate={translate}>
            {insight && (
                <div
                    className={styleGeneral.body}
                    onScroll={
                        /* istanbul ignore next: Jest dom doesnt provide actual rendering capability so unable to get scroll values */ (
                            event
                        ) => handleScroll(event)
                    }
                >
                    <div className={styleGeneral.stickToTopBack}>
                        <BtnBack
                            url={NavigationHelper.GenUrlInsight(
                                home.dashboardBeg,
                                home.dashboardEnd,
                                home.dashboardHasReferrer,
                                InsightHelper.ConvertInsightIdSingleToChildAndParent(home.insightId).parent
                            )}
                        />
                        {/* {newSize.w}x{newSize.h} */}
                    </div>
                    <div className={styleGeneral.insightEntryDetail}>
                        <ContributingEventDetail
                            home={home}
                            dispatch={dispatch}
                            translate={translate}
                            scrollActive1={scrollActive1}
                            insight={insight}
                            contributingEvent={insightEvent}
                            dataItemReadings={dataItemReadings}
                            setControls={setControls}
                        />
                    </div>
                </div>
            )}
        </ContentWrapperAll>
    );
}

export default ContributingEvent;
