import React from 'react';
import clsx from 'clsx';

import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import { ICallback, ITranslator } from '../../../types';
import BtnPrimary from '../btnPrimary';

function ContentWrapperEnrollmentPending({
    podderCentralCb,
    translate,
}: {
    podderCentralCb: ICallback<void>;
    translate: ITranslator;
}) {
    const podderCentralSlug = (
        <>
            {translate('enrollmentPending.podderCentral')}
            <span className={styleGuide.labelAlt}>®</span>
        </>
    );

    return (
        <div className={styleGuide.body}>
            <div
                className={clsx(
                    styleGeneral.errorPage,
                    styleGeneral.errorPageTest,
                    styleGeneral.layerTopmostLess1,
                    styleGuide.enrollmentPending
                )}
            >
                <div className={styleGuide.title}>{translate('enrollmentPending.title')}</div>
                <div className={styleGuide.body}>
                    {translate('enrollmentPending.subTitlePre')}&nbsp; {podderCentralSlug}&nbsp;
                    {translate('enrollmentPending.subTitlePost')}
                </div>
                <div>
                    <BtnPrimary caption={podderCentralSlug} cbClickFn={podderCentralCb} />
                </div>
            </div>
        </div>
    );
}

export default ContentWrapperEnrollmentPending;
