import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SystemHelper from '../helpers/SystemHelper';
import { homeInitialState } from '../store/home';
import { IState } from '../types';
import UiHelper from '../helpers/UiHelper';
import ContentWrapperAll from '../components/common/contentWrappers/ContentWrapperAll';

function Loading() {
    const dispatch = useDispatch();
    const home = SystemHelper.GetCleanState(
        useSelector((state: IState) => state.home),
        homeInitialState
    );
    const translate = (key: string, subs?: any) => UiHelper.Translate(home, key, subs);

    return (
        <ContentWrapperAll
            home={home}
            dispatch={dispatch}
            showIfInvalidEnrollment={false}
            translate={translate}
        ></ContentWrapperAll>
    );
}

export default Loading;
