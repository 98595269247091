import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { ICallback } from '../../types';
import UtilityHelper from '../../helpers/UtilityHelper';

function ChartTooltipPopover(contentMain: any, contentTooltip: any, onCloseCb?: ICallback<void>) {
    const tooltipBody = contentTooltip();

    if (tooltipBody) {
        return (
            <OverlayTrigger
                trigger="click"
                placement="auto"
                onToggle={(state: boolean) => {
                    if (!state && UtilityHelper.IsFunction(onCloseCb)) {
                        onCloseCb();
                    }
                }}
                overlay={
                    <Popover id="popover-basic">
                        <span id="popoverContent" onClick={() => document.body.click()}>
                            {tooltipBody}
                        </span>
                    </Popover>
                }
                rootClose
            >
                {contentMain()}
            </OverlayTrigger>
        );
    }

    return contentMain();
}

export default ChartTooltipPopover;
