import React from 'react';
import clsx from 'clsx';

import SystemHelper from 'src/helpers/SystemHelper';
import ConstantsHelper from 'src/helpers/ConstantsHelper';
import { ITranslator } from 'src/types';

import styleGuide from '../styles/styleGuide.module.scss';
import iconOpenArmBoy from '../assets/images/boy-open-arm.svg';

function UserNotAssignedToApplicationError({ translate }: { translate: ITranslator }) {
    const pwdAppUrl = SystemHelper?.getConfig(
        ConstantsHelper.urlParamsConstants.pwdAppUrl.urlKey,
        ConstantsHelper.urlParamsConstants.pwdAppUrl.settingsKey,
        ConstantsHelper.urlParamsConstants.pwdAppUrl.runtimeKey,
        false,
        true
    );

    const hcpAppUrl = SystemHelper?.getConfig(
        ConstantsHelper.urlParamsConstants.hcpAppUrl.urlKey,
        ConstantsHelper.urlParamsConstants.hcpAppUrl.settingsKey,
        ConstantsHelper.urlParamsConstants.hcpAppUrl.runtimeKey,
        false,
        true
    );

    return (
        <div className={styleGuide.userNotAssignedToApplicationError}>
            <div className={styleGuide.image}>
                <img src={iconOpenArmBoy} alt={translate('notFound.alt')} />
            </div>
            <div className={styleGuide.title}>{translate('userNotAssignedToApplicationError.title')}</div>
            <div className={styleGuide.description}>{translate('userNotAssignedToApplicationError.patientText')}</div>
            <div className={clsx(styleGuide.description, styleGuide.link)} data-testid="pwd-url">
                {pwdAppUrl}
            </div>
            <div className={styleGuide.description}>{translate('userNotAssignedToApplicationError.providerText')}</div>
            <div className={clsx(styleGuide.description, styleGuide.link)} data-testid="hcp-url">
                {hcpAppUrl}
            </div>
        </div>
    );
}

export default UserNotAssignedToApplicationError;
