import React from 'react';
import clsx from 'clsx';

import InsightSummaryCarousel from './summary/InsightSummaryCarousel';
import LoyaltyFrame from './loyalty/loyaltyFrame';

import { Insight3, Insight3GlucoseTrend } from '../../model/models';
import InsightsByPattern from '../../components/dashboard/insightsByPattern';
import iconNoInsights from '../../assets/images/no-insights.svg';
import styleGuide from '../../styles/styleGuide.module.scss';
import { ICallback, IDashboardCache, IHome, IInsight, ITranslator } from '../../types';

function InsightsFrame({
    home,
    translate,
    scrollActive1,
    scrollActive2,
    dashboard,
    summary,
    summaryGlucoseTrend,
    cbClickFn,
    cbCarouselSetIndexFn,
    cbLoyaltyCongrats,
    cbLoyaltyInsulin,
}: {
    home: IHome;
    translate: ITranslator;
    scrollActive1: boolean;
    scrollActive2: boolean;
    dashboard: IDashboardCache;
    summary: Insight3;
    summaryGlucoseTrend: Insight3GlucoseTrend;
    cbClickFn: ICallback<void>;
    cbCarouselSetIndexFn: ICallback<void>;
    cbLoyaltyCongrats?: any;
    cbLoyaltyInsulin?: any;
}) {
    const insights = dashboard?.insightIds?.map((insightId) => home.cachedInsights[insightId]);
    const noInsightsRender = () => {
        return (
            <div className={styleGuide.dashboardInsightsOff}>
                <img src={iconNoInsights} className={clsx(styleGuide.icon)} alt={translate('noInsights')} />
                <div className={styleGuide.title}>{translate('noInsights')}</div>
                <div className={styleGuide.body}>
                    {translate('noInsightsForThisWeek')}
                    <br></br>
                    <br></br>
                    {translate('seeYouNextWeek')}
                </div>
            </div>
        );
    };

    const insightsRender = (insightsToRender: IInsight[]) => {
        return (
            <div className={styleGuide.dashboardInsightsOn}>
                <InsightsByPattern
                    home={home}
                    key={`insights${1}`}
                    scrollActive1={scrollActive1}
                    scrollActive2={scrollActive2}
                    insights={insightsToRender}
                    testId={`insights${1}`}
                    cbClickFn={cbClickFn}
                    translate={translate}
                />
            </div>
        );
    };

    const pickRenderer = (insightsToRender: IInsight[]) => {
        if (insightsToRender?.length) {
            return insightsRender(insightsToRender);
        } else {
            return noInsightsRender();
        }
    };

    return home.loadingSemaphore !== 0 ? null : (
        <div className={styleGuide.solidBg} data-testid="insights-array">
            <InsightSummaryCarousel
                home={home}
                translate={translate}
                scrollActive2={scrollActive2}
                summary={summary}
                summaryGlucoseTrend={summaryGlucoseTrend}
                setIndex={cbCarouselSetIndexFn}
            />
            <LoyaltyFrame
                home={home}
                translate={translate}
                scrollActive2={scrollActive2}
                cbLoyaltyCongrats={cbLoyaltyCongrats}
                cbLoyaltyInsulin={cbLoyaltyInsulin}
            />
            {pickRenderer(insights)}
        </div>
    );
}

export default InsightsFrame;
