import React from 'react';
import clsx from 'clsx';

import styleGeneral from '../../styles/general.module.scss';
import styleGuide from '../../styles/styleGuide.module.scss';
import { IEdNote, ITranslator } from '../../types';

function InsightEdWarning({ notes, translate }: { notes: IEdNote[]; translate: ITranslator }) {
    return (
        <div className={styleGeneral.main}>
            <div className={styleGuide.edNotes}>
                <div className={clsx(styleGuide.warningFrame, styleGeneral.table)}>
                    {notes
                        ?.filter((note) => note.isWarning)
                        .map((note, idx) => (
                            <div key={`edNoteWarning${idx}`} className={clsx(styleGeneral.row, styleGuide.warning)}>
                                <span className={clsx(styleGeneral.cell, styleGuide.left)}>
                                    <img src={note.icon} alt={translate(note.alt)} className={styleGuide.icon} />
                                </span>
                                <span className={clsx(styleGeneral.cell, styleGuide.right)}>
                                    <div className={styleGeneral.table}>
                                        <div className={styleGeneral.row}>
                                            <div className={styleGeneral.cell}>
                                                <div className={styleGuide.text}>{translate('warning')}</div>
                                            </div>
                                        </div>
                                        <div className={styleGeneral.row}>
                                            <div className={styleGeneral.cell}>
                                                <div className={styleGuide.text}>{note.text}</div>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
}

export default InsightEdWarning;
