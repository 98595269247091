import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { IState } from '../types';
import SystemHelper from '../helpers/SystemHelper';
import styleGeneral from '../styles/general.module.scss';
import styleGuide from '../styles/styleGuide.module.scss';
import ContentWrapperAll from '../components/common/contentWrappers/ContentWrapperAll';
import UiHelper from '../helpers/UiHelper';
import { homeInitialState } from '../store/home';

function Help() {
    const dispatch = useDispatch();
    const home = SystemHelper.GetCleanState(
        useSelector((state: IState) => state.home),
        homeInitialState
    );
    const pauseItEnabled = home.control.pauseItEnabled;
    const translate = (key: string, subs?: any) => UiHelper.Translate(home, key, subs);

    useEffect(() => {
        SystemHelper.AppInsightsEvent(home, 'Help()', 'Mounted');
    }, [home]);

    return (
        <ContentWrapperAll home={home} dispatch={dispatch} showIfInvalidEnrollment translate={translate}>
            <div className={clsx(styleGeneral.body, styleGuide.help)}>
                <div className={clsx(styleGuide.header)}>
                    <div className={clsx(styleGuide.heading)}>{translate('help.heading')}</div>
                </div>

                <div className={styleGuide.helpCard}>
                    <div className={styleGuide.whiteBackground}>
                        <div className={styleGuide.cardTitle}>{translate('help.customerCare')}</div>
                        {pauseItEnabled ? (
                            <div className={styleGuide.linkWrapper}>
                                <a
                                    className={styleGuide.link}
                                    href={SystemHelper.GetRuntimeConfig('REACT_APP_CUSTOMER_CARE_EMAIL_LINK')}
                                    data-testid={'costumerCare-email'}
                                >
                                    {SystemHelper.GetRuntimeConfig('REACT_APP_CUSTOMER_CARE_EMAIL_LABEL')}
                                </a>
                            </div>
                        ) : (
                            <>
                                <div className={styleGuide.linkWrapper}>
                                    <a
                                        className={clsx(styleGeneral.link, styleGeneral.link_underline)}
                                        data-testid="customer-care-phone-number"
                                        href={SystemHelper.GetRuntimeConfig('REACT_APP_HELP_PHONE_NUMBER_LINK')}
                                    >
                                        {SystemHelper.GetRuntimeConfig('REACT_APP_HELP_PHONE_NUMBER_LABEL')}
                                    </a>
                                </div>
                                <div className={styleGuide.linkWrapper}>
                                    <a
                                        className={clsx(styleGeneral.link, styleGeneral.link_underline)}
                                        data-testid="contact-us-link"
                                        href={SystemHelper.GetRuntimeConfig('REACT_APP_HELP_CONTACT_US_URL_LINK')}
                                        target="__CONTACT_US__"
                                    >
                                        {SystemHelper.GetRuntimeConfig('REACT_APP_HELP_CONTACT_US_URL_LABEL')}
                                    </a>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className={styleGuide.helpCard}>
                    <div className={styleGuide.whiteBackground}>
                        <div className={styleGuide.cardTitle}>{translate('help.userGuide')}</div>
                        <div className={clsx(styleGuide.linkWrapper, styleGuide.podderText)}>
                            <a
                                data-testid={'userGuide-link'}
                                className={clsx(styleGeneral.link, !pauseItEnabled && styleGeneral.link_underline)}
                                href={SystemHelper.GetRuntimeConfig('REACT_APP_USER_GUIDE_URL')}
                                target="__GUIDE__"
                                download
                            >
                                {translate('help.omnipodDiscover')}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </ContentWrapperAll>
    );
}

export default Help;
