/**
 * Gibraltar API for PWD
 * API for presenting Gibraltar patient data.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: azheng@insulet.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Status of pod in given moment
 */
export interface DataItemModesPodStatuses { 
    /**
     * Pod status on given timestamp
     */
    status?: DataItemModesPodStatuses.StatusEnum;
    /**
     * ISO 8601 date of mode start
     */
    timestamp?: string;
}
export namespace DataItemModesPodStatuses {
    export type StatusEnum = 'Activated' | 'Deactivated' | 'Activating';
    export const StatusEnum = {
        Activated: 'Activated' as StatusEnum,
        Deactivated: 'Deactivated' as StatusEnum,
        Activating: 'Activating' as StatusEnum
    };
}