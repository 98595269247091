import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';

import ContributingEventChartBloodGlucose from './chart/ContributingEventChartBloodGlucose';
import ContributingEventChartInsulinDelivery from './chart/ContributingEventChartInsulinDelivery';

import ConstantsHelper from '../../helpers/ConstantsHelper';
import DateTimeHelper from '../../helpers/DateTimeHelper';
import InsightHelper from '../../helpers/InsightHelper';
import {
    IBolusEntry,
    IChartPayload,
    IContributingEvent,
    IHome,
    IIDataItemReadings,
    IInsight,
    IInsightAttr,
    IReadingsInfo,
    ITimeRange,
    ITranslator,
} from '../../types';
import PlankBolus from '../common/plankBolus';
import PlankEvent from '../common/plankEvent';
import styleGeneral from '../../styles/general.module.scss';
import styleGuide from '../../styles/styleGuide.module.scss';
import { InsightInsightsProp } from '../../model/insightInsightsProp';
import ChartHelper from '../../helpers/ChartHelper';
import EventHelper from '../../helpers/EventHelper';
import UiHelper from '../../helpers/UiHelper';

const renderBolus = (
    home: IHome,
    translate: ITranslator,
    units: any,
    bolusEntry: IBolusEntry,
    idx: number,
    delta: number
) => (
    <PlankBolus
        home={home}
        translate={translate}
        key={`plankBolus${idx}`}
        units={units}
        delta={delta}
        bolusEntry={bolusEntry}
    />
);
const renderUINonBolus = (
    home: IHome,
    translate: ITranslator,
    scrollActive1: boolean,
    contributingEvent: IContributingEvent,
    insightAttributes: IInsightAttr,
    typeStyle: string
) => (
    <div
        className={clsx(
            styleGuide.reading,
            styleGuide.banner,
            scrollActive1 && styleGeneral.eventsZoomInScroll1OnScroll2OnBorderOff
        )}
    >
        <div className={styleGuide.row}>
            <span className={styleGuide.label}>{insightAttributes.title2}</span>
            <span className={clsx(styleGuide.value, insightAttributes.style2)}>
                {`${DateTimeHelper.FormatTimeDelta(home, contributingEvent.duration, false, true) ?? '--'}`}
            </span>
        </div>
        <div className={styleGuide.row}>
            <span className={styleGuide.label}>{insightAttributes.title}</span>
            <span className={clsx(styleGuide.value, typeStyle)}>
                {EventHelper.GetEgvLabel(translate, contributingEvent?.extremeBGValue, false)}
            </span>
            <span className={styleGuide.unit}>{ConstantsHelper.UnitsTable.egv}</span>
        </div>
    </div>
);
const renderUIBolus = (
    home: IHome,
    translate: ITranslator,
    scrollActive1: boolean,
    insight: IInsight,
    contributingEvent: IContributingEvent,
    bolusDataPre: IBolusEntry[],
    bolusDataPost: IBolusEntry[]
) => (
    <>
        {bolusDataPre?.map((bolusEntry, idx) =>
            renderBolus(
                home,
                translate,
                ConstantsHelper.UnitsTable,
                bolusEntry,
                idx,
                idx > 0
                    ? moment(bolusDataPre[idx].bolusTime).diff(moment(bolusDataPre[idx - 1].bolusTime), 'seconds')
                    : -1
            )
        )}
        <div className={clsx(scrollActive1 && styleGeneral.eventsZoomInScroll1OnScroll2OnBorderOff)}>
            <PlankEvent
                home={home}
                translate={translate}
                units={ConstantsHelper.UnitsTable}
                delta={
                    bolusDataPre?.length > 0
                        ? moment(contributingEvent.beg).diff(
                              moment(bolusDataPre[bolusDataPre.length - 1].bolusTime),
                              'seconds'
                          )
                        : -1
                }
                event={contributingEvent}
                patternType={insight.patternType}
            />
        </div>
        {bolusDataPost?.map((bolusEntry, idx) =>
            renderBolus(
                home,
                translate,
                ConstantsHelper.UnitsTable,
                bolusEntry,
                idx,
                moment(bolusDataPost[idx].bolusTime).diff(
                    moment(idx === 0 ? contributingEvent.beg : bolusDataPost[idx - 1].bolusTime),
                    'seconds'
                )
            )
        )}
    </>
);
const getEventAttributes = (
    translate: ITranslator,
    home: IHome,
    insight: IInsight,
    contributingEvent: IContributingEvent,
    bolusDataPre: IBolusEntry[],
    bolusDataPost: IBolusEntry[],
    dataItemReadings: IIDataItemReadings
) => {
    let timeRangeData: ITimeRange;
    let bloodGlucoseDataAttributes: IChartPayload;
    let insulinDeliveryDataAttributes: IChartPayload;

    if (contributingEvent && insight && dataItemReadings) {
        timeRangeData = DateTimeHelper.FormatDateTimeRange(home, contributingEvent.beg, contributingEvent.end);
        const readingsWithBolusData = dataItemReadings.readingsCombo?.filter((r) => r.boluses?.length > 0);

        if (readingsWithBolusData) {
            readingsWithBolusData.forEach((readingEntry) => {
                const egv = readingEntry.egv;

                readingEntry.boluses?.forEach((bolusEntry) => {
                    const bolusEntryNew: IBolusEntry = {
                        ...bolusEntry,
                        egv,
                    };

                    if (bolusEntry.bolusTime <= contributingEvent.beg) {
                        bolusDataPre.push(bolusEntryNew);
                    } else {
                        bolusDataPost.push(bolusEntryNew);
                    }
                });
            });
        }

        insulinDeliveryDataAttributes = ChartHelper.GetInsulinDeliveryDataAttributes(
            translate('chart.mode'),
            translate('chart.mode2'),
            translate('chart.mode3'),
            dataItemReadings,
            contributingEvent
        );
        bloodGlucoseDataAttributes = ChartHelper.GetBloodGlucoseDataAttributes(
            translate('chart.glucoseTimeline'),
            translate('chart.glucoseTimeline2'),
            dataItemReadings.readingsCombo,
            contributingEvent,
            insight
        );
    }

    return {
        timeRangeData,
        bloodGlucoseDataAttributes,
        insulinDeliveryDataAttributes,
    };
};
const renderHeader = ({
    scrollActive1,
    insightAttributes,
    timeRangeData,
}: {
    scrollActive1: boolean;
    insightAttributes: IInsightAttr;
    timeRangeData: ITimeRange;
}) => {
    return (
        <div
            className={clsx(
                styleGuide.summary,
                styleGeneral.eventsZoomInScroll1OffScroll2Off,
                scrollActive1 && styleGeneral.eventsZoomInScroll1OnScroll2Off
            )}
        >
            <div className={clsx(styleGuide.header, styleGeneral.toCapitalize, scrollActive1 && styleGeneral.inScroll)}>
                {insightAttributes.scrn3Title}
            </div>
            <div className={clsx(styleGuide.timeRange, scrollActive1 && styleGeneral.inScroll)}>
                {timeRangeData.time}
                {timeRangeData.suffix}
            </div>
            <div className={clsx(styleGuide.dateRange, scrollActive1 && styleGeneral.inScroll2)}>
                {timeRangeData.date}
            </div>
        </div>
    );
};
const renderBody = ({
    home,
    translate,
    scrollActive1,
    insight,
    contributingEvent,
    insightAttributes,
    typeStyle,
    bolusDataPre,
    bolusDataPost,
    bloodGlucoseDataAttributes,
    insulinDeliveryDataAttributes,
    marginLeft,
    marginRight,
    setControls,
    xAxisTicks,
}: {
    home: IHome;
    translate: ITranslator;
    scrollActive1: boolean;
    insight: IInsight;
    contributingEvent: IContributingEvent;
    insightAttributes: IInsightAttr;
    typeStyle: string;
    bolusDataPre: IBolusEntry[];
    bolusDataPost: IBolusEntry[];
    bloodGlucoseDataAttributes: IChartPayload;
    insulinDeliveryDataAttributes: IChartPayload;
    marginLeft: number;
    marginRight: number;
    setControls: any;
    xAxisTicks: string[];
}) => (
    <>
        {insight?.insightType === InsightInsightsProp.InsightTypeEnum.Bolus
            ? renderUIBolus(home, translate, scrollActive1, insight, contributingEvent, bolusDataPre, bolusDataPost)
            : renderUINonBolus(home, translate, scrollActive1, contributingEvent, insightAttributes, typeStyle)}
        <ContributingEventChartBloodGlucose
            xAxisTicks={xAxisTicks}
            home={home}
            bloodGlucoseDataAttributes={bloodGlucoseDataAttributes}
            insight={insight}
            marginLeft={marginLeft}
            marginRight={marginRight}
            translate={translate}
        />
        <ContributingEventChartInsulinDelivery
            home={home}
            insulinDeliveryDataAttributes={insulinDeliveryDataAttributes}
            marginLeft={marginLeft}
            marginRight={marginRight}
            setControls={setControls}
        />
    </>
);
function ContributingEventDetail({
    home,
    dispatch,
    translate,
    scrollActive1,
    insight,
    contributingEvent,
    dataItemReadings,
    setControls,
}: {
    home: IHome;
    dispatch: any;
    translate: ITranslator;
    scrollActive1: boolean;
    insight: IInsight;
    contributingEvent: IContributingEvent;
    dataItemReadings: IIDataItemReadings;
    setControls: any;
}) {
    const control = home.control;
    const [windowSizeLast, setWindowSizeLast] = useState(control?.windowSize);
    const insightAttributes: IInsightAttr = InsightHelper.GetInsightAttributes(home, insight, translate);
    const typeStyle: string = insightAttributes.style;
    const marginLeft = 24;
    const marginRight = 30;
    const bolusDataPre: IBolusEntry[] = [];
    const bolusDataPost: IBolusEntry[] = [];
    const { timeRangeData, bloodGlucoseDataAttributes, insulinDeliveryDataAttributes } = getEventAttributes(
        translate,
        home,
        insight,
        contributingEvent,
        bolusDataPre,
        bolusDataPost,
        dataItemReadings
    );
    const xAxisTicks = ChartHelper.GetAxisTicksX(control?.windowSize, insulinDeliveryDataAttributes?.crossOvers);

    useEffect(() => {
        // Force chart components to redraw upon screen resize
        if (setControls && !home.loadingSemaphore && windowSizeLast?.width !== control?.windowSize?.width) {
            const fillGaps = control?.readingsInfo?.fillGaps;

            UiHelper.SetLoadingSemaphore(dispatch, 1);
            setControls({ readingsInfo: { fillGaps: !fillGaps } } as IReadingsInfo);

            setTimeout(() => {
                setControls({ readingsInfo: { fillGaps } } as IReadingsInfo);
                UiHelper.SetLoadingSemaphore(dispatch, -1);
            }, 1000);

            setWindowSizeLast(control?.windowSize);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [control?.windowSize]);

    return !contributingEvent ? null : (
        <div className={styleGuide.eventDetail}>
            <div className={styleGuide.whiteBackground} id={'eventScrollTop'}>
                {renderHeader({
                    scrollActive1,
                    insightAttributes,
                    timeRangeData,
                })}
                {renderBody({
                    home,
                    translate,
                    scrollActive1,
                    insight,
                    contributingEvent,
                    insightAttributes,
                    typeStyle,
                    bolusDataPre,
                    bolusDataPost,
                    bloodGlucoseDataAttributes,
                    insulinDeliveryDataAttributes,
                    marginLeft,
                    marginRight,
                    setControls,
                    xAxisTicks,
                })}
            </div>
        </div>
    );
}

export default ContributingEventDetail;
