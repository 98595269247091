import React from 'react';
import { TextField } from '@mui/material';
import clsx from 'clsx';

import styleGuide from '../../styles/styleGuide.module.scss';
import { ICallback } from '../../types';

function SmsNumberInput({
    value,
    label,
    disabled,
    onChange,
}: {
    value: string;
    label?: string;
    disabled?: boolean;
    onChange?: ICallback<void>;
}) {
    const handleChange = (event: any) => {
        onChange(event.target.value);
    };

    const handleBlur = () => {
        if (window.scrollY > 0) {
            window.scrollTo(0, 0);
        }
    };

    return (
        <div className={styleGuide.smsNumberInput}>
            <TextField
                className={clsx(styleGuide.phoneCode, { [styleGuide.disabled]: disabled })}
                disabled
                type="tel"
                value="+1"
            />
            <TextField
                className={styleGuide.numberInput}
                data-testid="sms-number-input__textfield"
                fullWidth
                type="tel"
                label={label}
                value={value}
                disabled={disabled}
                onChange={handleChange}
                onBlur={handleBlur}
            />
        </div>
    );
}

export default SmsNumberInput;
