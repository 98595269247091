/**
 * Gibraltar API for PWD
 * API for presenting Gibraltar patient data.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: azheng@insulet.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BegEndObject } from './begEndObject';
import { WindowEventObject } from './windowEventObject';

/**
 * Insight insights property.
 */
export interface InsightInsightsProp extends BegEndObject {
    /**
     * Feedback value -- 0/null=not set, 1=yes, 2=no
     */
    feedbackValue?: InsightInsightsProp.FeedbackValueEnum;
    /**
     * Threshold at or above which to flag high Readings.
     */
    highEgvMgDlThreshold?: number;
    /**
     * Threshold at or below which to flag high Readings.
     */
    lowEgvMgDlThreshold?: number;
    patternType?: InsightInsightsProp.PatternTypeEnum;
    /**
     * Insulet data ID.
     */
    insightId?: string;
    insightType?: InsightInsightsProp.InsightTypeEnum;
    /**
     * Insulet data ID.
     */
    profileId?: string;
    windowEgvEvent?: Array<WindowEventObject>;
}
export namespace InsightInsightsProp {
    export type FeedbackValueEnum = '0' | '1' | '2';
    export const FeedbackValueEnum = {
        _0: '0' as FeedbackValueEnum,
        _1: '1' as FeedbackValueEnum,
        _2: '2' as FeedbackValueEnum,
    };
    export type PatternTypeEnum = 'high' | 'low';
    export const PatternTypeEnum = {
        High: 'high' as PatternTypeEnum,
        Low: 'low' as PatternTypeEnum,
    };
    export type InsightTypeEnum = 'time_of_day' | 'bolus';
    export const InsightTypeEnum = {
        TimeOfDay: 'time_of_day' as InsightTypeEnum,
        Bolus: 'bolus' as InsightTypeEnum,
    };
}
