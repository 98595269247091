// eslint-disable-next-line import/named
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import { reducer } from '../store/root-reducer';
import { IHome } from '../types';

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export type RootState = ReturnType<typeof store.getState>;

export default store;

export const useTypedSelector: TypedUseSelectorHook<IHome> = useSelector;
