import React from 'react';
import clsx from 'clsx';

import LoyaltyInsulinUsageBlock from './loyaltyInsulinUsageBlock';
import LoyaltyInsulinModeUsageChart from './LoyaltyInsulinModeUsageChart';

import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import { IHome, IInsulinUsage, ITranslator } from '../../../types';
import BtnPrimary from '../../common/btnPrimary';
import NavigationHelper from '../../../helpers/NavigationHelper';

const anchor = 'loyaltyInsulinUsageAnchor';

const insulinUsage = ({
    home,
    summary,
    translate,
    cbLoyaltyInsulin,
}: {
    home: IHome;
    summary: IInsulinUsage;
    translate: ITranslator;
    cbLoyaltyInsulin: any;
}) => (
    <div data-testid="loyalty_insulin_mode_usage" id={anchor} key={anchor} className={styleGuide.InsightCard}>
        <div className={styleGuide.whiteBackgroundAlt}>
            <div className={styleGuide.headerLoyaltyAlt}>
                <div className={styleGuide.loyaltyInsulinUsage}>
                    <LoyaltyInsulinUsageBlock translate={translate} tokenHeader={'loyalty.insulin.title'} />
                    <LoyaltyInsulinModeUsageChart home={home} translate={translate} showUnits={false} />

                    <div className={styleGuide.loyaltyInsulinUsageRow}>
                        <div className={clsx(styleGeneral.table, styleGuide.loyaltyRow3)}>
                            <div className={clsx(styleGeneral.row, styleGuide.top)}>
                                <span className={clsx(styleGeneral.cell, styleGuide.cell)}>
                                    <span className={clsx(styleGuide.caption)}>
                                        {translate('loyalty.insulin.avgTotalDailyInsulin')}
                                    </span>
                                </span>
                                <span className={clsx(styleGeneral.cell, styleGuide.cell)}>
                                    <span className={clsx(styleGuide.caption)}>
                                        {translate('loyalty.insulin.avgNumOfBolusesPerDay')}
                                    </span>
                                </span>
                            </div>
                            <div className={clsx(styleGeneral.row, styleGuide.bottom)}>
                                <span className={clsx(styleGeneral.cell, styleGuide.cell)}>
                                    {summary.dailyUsage.avgInsulinTotal}
                                    <span className={styleGuide.loyaltyValueUnit}>U</span>
                                </span>
                                <span className={clsx(styleGeneral.cell, styleGuide.cell)}>
                                    {summary.dailyUsage.bolusStatistics?.avgNumber}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className={styleGuide.loyaltyInsulinUsageRow}>
                        <BtnPrimary
                            data-testid={`anchor_${anchor}`}
                            caption={translate('viewDetails')}
                            cbClickFn={() => cbLoyaltyInsulin(anchor)}
                            url={NavigationHelper.GenUrlInsulinUsageInsights(
                                home.dashboardBeg,
                                home.dashboardEnd,
                                home.dashboardHasReferrer
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

function LoyaltyInsulinModeUsage({
    home,
    translate,
    cbLoyaltyInsulin,
}: {
    home: IHome;
    translate: ITranslator;
    cbLoyaltyInsulin?: any;
}) {
    const summary: IInsulinUsage = home?.currentInsulinUsage;

    return !home?.currentInsulinUsage?.dailyUsage?.insulinRatio
        ? null
        : insulinUsage({ home, summary, translate, cbLoyaltyInsulin: cbLoyaltyInsulin });
}

export default LoyaltyInsulinModeUsage;
