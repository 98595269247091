import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useDispatch, useSelector } from 'react-redux';

import SystemHelper from 'src/helpers/SystemHelper';
import { IState } from 'src/types';
import UiHelper from 'src/helpers/UiHelper';
import { homeInitialState, signInUrlCreator } from 'src/store/home';
import { sendAzureEvent } from 'src/helpers/appInsights';

import LoginError from './LoginError';

import ConstantsHelper from '../helpers/ConstantsHelper';
import styleGuide from '../styles/styleGuide.module.scss';

export const sendLoginAnalyticsEvent = (analyticsEventName: string) =>
    sendAzureEvent(analyticsEventName, { signInUrl: document.URL });

function LoginCallback() {
    const { oktaAuth, authState } = useOktaAuth();
    const dispatch = useDispatch();
    const [callbackError, setCallbackError] = useState(null);
    const home = SystemHelper.GetCleanState(
        useSelector((state: IState) => state.home),
        homeInitialState
    );
    const translate = (key: string, subs?: any) => UiHelper.Translate(home, key, subs);

    useEffect(() => {
        oktaAuth
            .handleLoginRedirect()
            .then(() => {
                sendLoginAnalyticsEvent(ConstantsHelper.analyticsEventNames.SUCCESSFUL_LOGIN);
                dispatch(signInUrlCreator({ signInUrl: document.URL }));
            })
            .catch((e) => {
                setCallbackError(e);
                sendLoginAnalyticsEvent(ConstantsHelper.analyticsEventNames.FAILED_LOGIN);
                dispatch(signInUrlCreator({ signInUrl: document.URL }));
            });
    }, [oktaAuth]);

    useEffect(() => {
        if (authState?.error) {
            sendLoginAnalyticsEvent(ConstantsHelper.analyticsEventNames.FAILED_LOGIN);
            dispatch(signInUrlCreator({ signInUrl: document.URL }));
        }
    }, [authState?.error]);

    const authError = authState?.error;
    const displayError = callbackError || authError;

    if (displayError) {
        return (
            <div className={styleGuide.loginCallback}>
                <LoginError error={displayError} translate={translate} />
            </div>
        );
    }

    return null;
}

export default LoginCallback;
