import React from 'react';
import clsx from 'clsx';

import styleGeneral from '../../../styles/general.module.scss';
import styleGuide from '../../../styles/styleGuide.module.scss';
import { ICallback, ITranslator } from '../../../types';
import BtnPrimary from '../btnPrimary';

function ContentWrapperEnrollmentRevoked({
    podderCentralCb,
    translate,
}: {
    podderCentralCb: ICallback<void>;
    translate: ITranslator;
}) {
    return (
        <div className={styleGuide.body}>
            <div
                className={clsx(
                    styleGeneral.errorPage,
                    styleGeneral.errorPageTest,
                    styleGeneral.layerTopmostLess1,
                    styleGuide.enrollmentRevoked
                )}
            >
                <div className={styleGuide.title}>{translate('enrollmentRevoked.title')}</div>
                <div className={styleGuide.body}>{translate('enrollmentRevoked.subTitle')}</div>
                <div>
                    <BtnPrimary caption={translate('enrollmentRevoked.enrollNow')} cbClickFn={podderCentralCb} />
                </div>
            </div>
        </div>
    );
}

export default ContentWrapperEnrollmentRevoked;
